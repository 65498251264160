<template>
  <el-dialog
    v-if="open"
    :title="$t('messages.counselor.deleted.title')"
    :visible.sync="open"
    :modal-append-to-body="false"
    custom-class="el-dialog--small"
    @before-close="$emit('closeDialog')"
  >
    <div v-html="$t('messages.counselor.deleted.content')" />

    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        @click="closeDialog(true)"
      >
        {{ $t('system.ok') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    open: false
  }),

  watch: {
    show: function (newVal) {
      this.open = newVal
    },
    open: function (newVal) {
      if (newVal === false) {
        this.$emit('close-dialog')
      }
    }
  },

  methods: {
    closeDialog () {
      this.$emit('forward-dialog')
    }
  }
}
</script>
